// Add all theme related content here...

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)

// Custom
@import "variables";
@import "mixins";
// @import "~@ng-select/ng-select/themes/default.theme.css";

// Fonts
// @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
// @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");

$theme-colors: (
  "primary": $primary-blue-color,
  "secondary": $secondary-gray-color,
  "success": $success-green-color,
  "warning": $warning-yellow-color,
  "danger": $danger-red-color,
  "light": $light-white-color,
  "dark": $dark-gray-color,
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Alerts
$alert-bg-level: 0;
$alert-border-radius: $border-radius;

// Button overrides
@import "components/buttons";

// Forms

$text-muted: $secondary-gray-color;
$input-border-color: $secondary-gray-color;
$input-padding-y: 0.65rem;
$input-padding-x: 1.3rem;
$input-font-size: 14px;
$input-font-weight: 600;
$input-line-height: 1.2em;
$input-color: $dark-gray-color;
$input-height: add(
  $input-line-height,
  add($input-padding-y * 2, $input-height-border, false)
);

$custom-control-indicator-size: 1.5rem;
$custom-checkbox-indicator-border-radius: 0;

.form-control {
  &::placeholder {
    font-size: 15px;
    color: $secondary-gray-color;
    padding: 0.25rem 0rem;
  }
}

// tooltip

$tooltip-max-width: 230px;
$tooltip-color: $dark-gray-color;
$tooltip-bg: $white;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;

$tooltip-arrow-width: 1rem;
$tooltip-arrow-height: 0.5rem;
$tooltip-arrow-color: $tooltip-bg;

.tooltip .tooltip-inner {
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #0000001a;
  border: 1px solid #e0e0e0;
}

// Modal

$modal-sm: 450px;
$modal-md: 800px;
$modal-lg: 900px;
$modal-xl: 1140px;

// nav & tabs

$nav-link-font-size: 18px;
$nav-link-font-weight: 600;
$nav-link-color: #000000;
$nav-link-hover-color: $white;
$nav-link-disabled-color: #000000;

$nav-tabs-border-color: $primary-blue-color;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: $primary-blue-color;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg;

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

.close {
  color: $dark-gray-color !important;
}

.alert-success {
  @include alert-variant(
    $success-green-color,
    $success-green-color,
    $dark-gray-color
  );
}

.alert-danger {
  @include alert-variant(
    $danger-light-red-color,
    $danger-light-red-color,
    $dark-gray-color
  );
}

.alert-light {
  @include alert-variant($light-white-color, #e0e0e0, $dark-gray-color);
}
