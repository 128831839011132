// Add all custom classes here...

.ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.flex-unset {
  flex: unset;
}

.size-12 {
  font-size: 12px;
}

.size-14 {
  font-size: 14px;
}

.size-16 {
  font-size: 16px;
}

.size-18 {
  font-size: 18px;
}

.size-20 {
  font-size: 20px;
}

.size-24 {
  font-size: 24px;
}

.size-28 {
  font-size: 28px;
}

.size-32 {
  font-size: 32px;
}

.weight-500 {
  font-weight: 500;
}

.weight-bold {
  font-weight: bold;
}

.weight-600 {
  font-weight: 600;
}

.weight-800 {
  font-weight: 800;
}

.underline {
  text-decoration: underline;
}

.text-light-black {
  color: #333333 !important;
}

.text-light-gray-1 {
  color: #8392a7;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

h5 {
  font: normal normal 600 16px/13px "Open Sans";
  margin-bottom: 15px;
}

.mb-4-2 {
  margin-bottom: 2rem;
}

.mb-3-2 {
  margin-bottom: 1.25rem;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-20 {
  margin-bottom: 20px;
}

.h-70 {
  height: 70%;
}

.h-60 {
  height: 60%;
}

.h-50 {
  height: 50%;
}
.mh-50 {
  min-height: 50%;
}

.h-40 {
  height: 40%;
}

.h-30 {
  height: 30%;
}

.custom-btn {
  min-width: 100px;
}

.flex-column-sm {
  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.modal-content {
  height: 100%;
}

.modal-max-height {
  height: calc(100% - 3.5rem);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 0;
}

.text-gray-header {
  color: #607d8b;
}

.spacer {
  min-height: 50px;
  background-color: transparent;
}

.status {
  line-height: 1.3;
  min-height: 16px;
  &.inprogress-status,
  &.pending-status {
    color: #ff9800;
  }
  &.accepted-status {
    color: #4caf50;
  }
  &.completed-status {
    color: #102331;
  }
  &.declined-status,
  .rejected-status {
    color: #ff5a5a;
  }
}

.border-radius-25 {
  border-radius: 25px;
}

.px-80 {
  padding-left: 80px;
  padding-right: 80px;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.default-border-radius {
  border-radius: 6px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.text-cursor {
  cursor: text !important;
}

.text-gray-200 {
  color: #9ca9af;
}

.btn:disabled,
.custom-control:disabled,
.form-control:disabled {
  cursor: not-allowed !important;
}

.custom-popover {
  margin: 0 !important;
  .arrow {
    display: none;
  }

  .popover-body {
    display: flex;
    flex-direction: column;
    max-width: 180px;
    max-height: 160px;
    overflow: auto;
    padding: 16px;
    border-radius: 5px;
    // -webkit-backdrop-filter: blur(30px);
    // backdrop-filter: blur(30px);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #f5f6f8;
    background-color: #f5f6f8;
  }

  .selected-items-popover-item {
    width: 100%;
    max-width: 100%;
    font-size: 12px;
    color: #42526e;
    line-height: 1.3;
    min-height: 16px;
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

//  Styles for common LFx header.
.lfx-main-header-expanded {
  .playground-layout {
    margin-top: 105px !important;
    transition: all 0.3s ease-in;
  }

  .header-container {
    top: 105px !important;
    transition: all 0.3s ease-in;
  }
}
